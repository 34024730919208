import React from "react";
import { SwiperSlide } from "swiper/react";
import { Pagination, Controller, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { SanityHomeHero } from "@graphql-types";
import { AbsoluteContainer, Container } from "@util/standard";
import { BlocksContent, Image } from "@global";
import {
  StyledSwiper,
  ImageWrapper,
  SlideTextWrapper,
  DateContainer,
  DateBlock,
  HeroWrapper,
} from "./home.styles";

interface Props {
  data: SanityHomeHero;
}

function HomeHero({ data }: Props) {
  if (data == null) return null;

  const currentDate = new Date();
  const splitDate = currentDate.toString().split(" ");
  const formattedDate = {
    date: splitDate[2],
    day: splitDate[0],
    month: splitDate[1],
    year: splitDate[3][2] + splitDate[3][3],
  };

  return (
    <HeroWrapper>
      {data.heroSlides && (
        <StyledSwiper
          modules={[Controller, Pagination, Navigation]}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
        >
          {data.heroSlides.map(slide => {
            if (slide == null) return null;

            return (
              <SwiperSlide style={{ display: "flex" }} key={slide._key}>
                <Container
                  flexDirection="column"
                  width="56%"
                  tabletWidth="100%"
                  margin="auto"
                  tabletMargin="auto"
                  mobileMargin="28vh auto 0 auto"
                >
                  {slide.slideImage && (
                    <ImageWrapper>
                      <Image data={slide.slideImage} />
                    </ImageWrapper>
                  )}
                  <SlideTextWrapper>
                    <BlocksContent data={slide.slideText} />
                  </SlideTextWrapper>
                </Container>
              </SwiperSlide>
            );
          })}
        </StyledSwiper>
      )}

      {/* DATE DISPLAY */}
      <AbsoluteContainer width="100%" top="30%" tabletPositioning={{ top: "10%" }}>
        <DateContainer>
          <Container tabletWidth="100%">
            <DateBlock marginOn>{formattedDate.day.toUpperCase()}</DateBlock>
            <DateBlock>{formattedDate.date}</DateBlock>
          </Container>
          <Container tabletWidth="100%">
            <DateBlock marginOn>{formattedDate.month.toUpperCase()}</DateBlock>
            <DateBlock>{formattedDate.year}</DateBlock>
          </Container>
        </DateContainer>
      </AbsoluteContainer>
      {/* SPLIT COLOR BACKGROUND */}
      <AbsoluteContainer
        height="100%"
        width="100%"
        top="0px"
        left="0px"
        tabletFlexDirection="column"
      >
        <Container width="50%" height="100%" tabletWidth="100%" backgroundColor="teal" />
        <Container width="50%" tabletWidth="100%" height="100%" backgroundColor="skyBlue" />
      </AbsoluteContainer>
    </HeroWrapper>
  );
}

export default HomeHero;
