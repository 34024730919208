import React, { useEffect } from "react";
import { PageProps, graphql, navigate } from "gatsby";

import { Query } from "@graphql-types";
import {
  isSanityColumnContent,
  isSanityTestimonialsSlider,
  isSanityFeaturedCollections,
  isSanityThemeSelector,
} from "@util/types";
import { ColumnContent, TestimonialsSlider, ThemeSelector, ParallaxColumns } from "@global";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import HomeHero from "@components/home/homeHero";
import { useStore } from "@state/store";
import { checkIsMicroSite } from "@util/helper";

interface Props extends PageProps {
  data: Query;
}

export default function IndexPage({ data }: Props) {
  if (data.sanityHome == null) {
    return null;
  }

  const isMicroSite = checkIsMicroSite();

  const { closeAgeRestrictionPopup, ageRestrictionPopupVisible } = useStore();

  useEffect(() => {
    if (ageRestrictionPopupVisible) {
      closeAgeRestrictionPopup();
    }

    if (isMicroSite) {
      navigate("/calendars");
    }
  }, []);

  const { seo, homeHero, pageContent } = data.sanityHome;

  return (
    <>
      <SEO seoData={seo} />
      {!isMicroSite && (
        <Layout>
          {homeHero && <HomeHero data={homeHero} />}
          {pageContent && (
            <div style={{ position: "relative" }}>
              {pageContent.map(data => {
                if (isSanityColumnContent(data)) {
                  return <ColumnContent data={data} key={data._key} />;
                }
                if (isSanityFeaturedCollections(data)) {
                  return <ParallaxColumns data={data} key={data._key} />;
                }
                if (isSanityThemeSelector(data)) {
                  return <ThemeSelector data={data} key={data._key} />;
                }
                if (isSanityTestimonialsSlider(data)) {
                  return <TestimonialsSlider data={data} key={data._key} />;
                }
                return null;
              })}
            </div>
          )}
        </Layout>
      )}
    </>
  );
}

export const query = graphql`
  {
    sanityHome {
      seo {
        ...sanitySeo
      }
      pageContent {
        ... on SanityThemeSelector {
          ...sanityThemeSelector
        }
        ... on SanityTestimonialsSlider {
          _key
          _type
          header
          testimonials {
            ...sanityTestimonial
          }
        }
        ... on SanityFeaturedCollections {
          ...sanityFeaturedCollections
        }
        ... on SanityColumnContent {
          ...sanityColumnContent
        }
      }
      homeHero {
        heroSlides {
          _key
          slideText {
            _rawBlockContent(resolveReferences: { maxDepth: 2 })
          }
          slideImage {
            _key
            altText
            asset {
              url
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        _type
        _key
      }
    }
  }
`;
